















import {Component, Vue} from 'vue-property-decorator';
import DashboardNavigation from "../components/DashboardNavigation.vue";

Component({
    name: "DashboardLayout",
    components: { DashboardNavigation }
})
export default class DashboardLayout extends Vue {

    get hasMenu() {
        return false;
    }
}
