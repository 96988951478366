

























































import {Component, Vue} from "vue-property-decorator";

@Component({
    name: 'DashboardNav'
})
export default class DashboardNav extends Vue {
    protected isMenuOpen = false;
    protected closable = true;

    public extendMenu() {
        //this.isMenuOpen = !this.isMenuOpen;
    }
}
